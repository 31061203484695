<template>
  <div>
    <!-- No record card::begin -->
    <div class="card card-dark-withbg" v-if="!loading && data.length == 0">
      <div class="card-body p-0">
        <div class="card-px text-center py-20 my-10">
          <h2 class="fs-2x fw-bolder mb-10" v-if="moduleName">
            {{ moduleName }}
          </h2>
          <p class="text-gray-400 fs-4 fw-bold">
            <template v-if="moduleSearch">
              Aramanıza uygun bir kayıt bulamadık.
            </template>
            <template v-else> Henüz eklenmiş bir kaydınız yok. </template>
          </p>
          <p
            class="text-gray-400 fs-4 fw-bold"
            v-if="moduleCreateEvent === true"
          >
            Kaydınızı oluşturun ve hemen kullanmaya başlayın!
          </p>
          <div
            @click="openCreateModal"
            v-if="moduleCreateEvent === true"
            class="btn btn-primary mt-10 btn-sm rounded-sm"
          >
            <i class="fas fa-plus-circle"></i> Yeni Ekle
          </div>
          <div
            v-if="showFilterClearButton"
            @click="clearFilter"
            class="btn btn-danger mt-10 ml-3 btn-sm rounded-sm"
          >
            <i class="fas fa-times-circle"></i> Filtreleri Temizle
          </div>
        </div>
      </div>
    </div>
    <!-- No record card::end -->

    <div class="list" v-else>
      <div class="list-header card" :style="headerStyle">
        <div
          class="item  font-weight-boldest"
          v-for="(head, hI) in header"
          :key="hI"
        >
          <b-form-group v-if="head.checkboxField" class="m-0">
            <label class="checkbox checkbox-square">
              <input
                type="checkbox"
                id="allSelectedInCheckbox"
                v-model="allSelectedInCheckbox"
                @click="checkboxAllClicked"
              />
              <span></span>
            </label>
          </b-form-group>
          <slot :name="`header.${head.value}`" :item="head" v-else>
            {{ head.text ? head.text : '' }}
          </slot>
        </div>
      </div>

      <div class="loading" v-if="loading">
        <div class="loading_line_wrapper">
          <div class="loading_line">
            <div class="loading_line_inner loading_line_inner--1"></div>
            <div class="loading_line_inner loading_line_inner--2"></div>
          </div>
        </div>
        <span
          class="text font-size-md font-weight-bold text-muted p-2"
          v-if="data.length <= 0"
          >Yükleniyor</span
        >
      </div>

      <Card v-else>
        <div class="list-content">
          <div v-for="(item, index) in data">
            <div class="item" :style="headerStyle">
              <div
                class="field text-dark font-weight-normal"
                v-for="(head, dI) in header"
                :key="`${dI}${index}`"
              >
                <!-- copyField will work if true -->
                <CopyButtonLabel
                  v-if="head.copyField"
                  :value="item[head.value] ? item[head.value] : 'N/A'"
                />

                <b-form-group v-else-if="head.checkboxField" class="m-0">
                  <label class="checkbox checkbox-square">
                    <input
                      type="checkbox"
                      v-model="selectedCheckbox[index]"
                      ref="`selectedCheckbox[${index}]`"
                      :id="`selectedCheckbox[${index}]`"
                      :data-id="item[head.value]"
                      @click="checkboxClicked(index)"
                    />
                    <span></span>
                  </label>
                </b-form-group>

                <template v-else>
                  <router-link
                    v-if="detailUrl && detailUrl != '' && head.clicked"
                    :to="`${detailUrl}/${item.id}`"
                    class="text-dark font-weight-normal"
                  >
                    <slot :name="`item.${head.value}`" :item="item">
                      {{
                        item[head.value]
                          ? $customFunctions.controlSpace(item[head.value])
                          : 'N/A'
                      }}
                    </slot>
                  </router-link>

                  <!-- slot will work if default -->
                  <span
                    :class="{ 'cursor-pointer': head.clicked }"
                    @click="clickField(item, head)"
                    v-else
                  >
                    <slot :name="`item.${head.value}`" :item="item">
                      {{
                        item[head.value]
                          ? $customFunctions.controlSpace(item[head.value])
                          : 'N/A'
                      }}
                    </slot>
                  </span>
                </template>
              </div>
            </div>

            <slot
              name="subRow"
              :item="item"
              :selectedItemId="selectedItemId"
              :openNewItem="openNewItem"
            ></slot>
          </div>

          <span
            v-if="data.length <= 0 && !loading"
            class="text-center font-size-md font-weight-bold text-muted p-1"
          >
            Kayıt bulunamadı.
          </span>
        </div>
      </Card>
    </div>

    <slot></slot>

    <CustomPagination
      v-if="
        !loading &&
        data.length > 0 &&
        pagination &&
        pagination.constructor === Object &&
        Object.keys(pagination).length > 0
      "
      :page="pagination.current_page"
      :totalPages="pagination.total_pages"
      :totalItems="pagination.total_items"
      @changePage="changePage"
    />
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    header: Array,
    data: Array,
    pagination: Object,
    loading: Boolean,
    detailUrl: '',
    moduleName: '',
    moduleCreateEvent: {
      type: Boolean | String,
      default: false,
    },
    moduleSearch: '',
    selectedItemId: '',
    openNewItem: {
      type: Boolean,
      default: false,
    },
    showFilterClearButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxSelectedIds: [],
      allSelectedInCheckbox: false,
      selectedCheckbox: [],
    };
  },
  computed: {
    headerStyle() {
      if (!this.header) return;

      let mappingHeader = this.header
        .map((item) => {
          return item.size ? item.size : '1fr';
        })
        .join(' ');

      return {
        gridTemplateColumns: mappingHeader,
      };
    },
  },
  created() {
    this.setDefaultCheckboxValues();
  },
  methods: {
    setDefaultCheckboxValues() {
      if (!this.data) return;

      for (let index = 0; index < this.data.length; index++) {
        this.selectedCheckbox[index] = false;
      }

      this.checkboxSelectedIds = [];
    },
    changePage(value) {
      this.$emit('updatePage', { current_page: value });
    },
    clickField(item, head) {
      if (!head.clicked) return false;

      this.$emit(`click`, item, head.value);
    },
    openCreateModal() {
      this.$emit('openCreateModal');
    },
    clearFilter() {
      this.$emit('clearFilter', true);
    },
    checkboxAllClicked() {
      setTimeout(() => {
        this.data.forEach((element) => {
          if (
            this.checkboxSelectedIds.includes(element.id) ==
            !this.allSelectedInCheckbox
          ) {
            document.querySelector(`[data-id='${element.id}']`).click();
          }
        });
      }, 10);
    },
    checkboxClicked(index) {
      setTimeout(() => {
        var clickedCheckbox = document.getElementById(
          `selectedCheckbox[${index}]`
        );
        let _id = clickedCheckbox.getAttribute('data-id');

        if (this.checkboxSelectedIds.includes(_id) === false) {
          this.checkboxSelectedIds.push(_id);

          if (this.selectedCheckbox.every((element) => element === true)) {
            //Eğer hepsi seçili ise, tümü seçili kısmını işaretlemek gerekiyor.
            let allCheck = document.getElementById('allSelectedInCheckbox');
            allCheck.checked = true;
            allCheck.dispatchEvent(new Event('change'));
          }
        } else {
          this.checkboxSelectedIds.splice(
            this.checkboxSelectedIds.indexOf(_id),
            1
          );
          //Eğer hepsi seçili kısmı işaretli ise onu kaldırmak gerekiyor.
          let allCheck = document.getElementById('allSelectedInCheckbox');
          allCheck.checked = false;
          allCheck.dispatchEvent(new Event('change'));
        }
      }, 10);
    },
  },
  watch: {
    checkboxSelectedIds: function () {
      this.$emit('selectedItemIdsChanged', this.checkboxSelectedIds);
    },
    data: function () {
      this.setDefaultCheckboxValues();
    },
  },
};
</script>